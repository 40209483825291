.desktop-menu {
  text-align: center;
  padding: 0;
}

.desktop-menu .logo-container {
  padding: 0 1.5rem;
}

.desktop-menu .logo-container .logo {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
}

.desktop-menu .logo-container .logo::before {
  width: 0;
}

.desktop-menu .menu-container {
  justify-content: flex-end;
  padding: 0 1.5rem;
}

.desktop-menu .menu-container .menu::before {
  width: 0;
}

.desktop-menu .menu-container .menu {
  border-left: 0 !important;
  font-weight: bold;
}