
.product-wrapper {
  position: relative;
  padding: 2rem 4rem;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif !important;
}

.products-container {
  font-family: "Poppins", sans-serif !important;
}

.product-wrapper .heading {
  font-family: "Poppins", sans-serif !important;
}

.poppins-font {
  font-family: "Poppins", sans-serif !important;
}

.ltr-wrapper {
  background: rgb(69,122,68);
  background: linear-gradient(90deg, rgba(69,122,68,1) 0%, rgba(76,135,75,1) 50%, rgba(160,189,159,1) 100%);
  animation: loadProgressBar 2s ease-in-out;
  color: white;
}

.rtl-wrapper {
  background: rgb(152,150,150);
  background: linear-gradient(90deg, rgba(74,74,74,1) 0%, rgba(114,112,113,1) 50%, rgba(152,150,150,1) 100%);
  animation: reverseLoadProgressBar 2s ease-in-out;
  color: white;
}

.description-container {
  display: flex;
  justify-content: space-between;
}

.description {
  width: 70%;
}

.learn-more {
  width: 30%;
  color: white;
  cursor: pointer;
  text-align: right;
}

.learn-more:hover {
  color: #4183c4;
}

.rtl-wrapper .learn-more {
  color: white;
}

.rtl-wrapper .learn-more:hover {
  color: #4183c4;
}

.product-heading {
  font-family: "Poppins", sans-serif !important;
  font-size: 3rem;
  padding: 4rem 4rem;
  font-weight: 600;
}
 
@keyframes loadProgressBar {
  0% { left: -100vw; }
  100% { left: 0; }
}

@keyframes reverseLoadProgressBar {
  0% { left: 100vw; }
  100% { left: 0; }
}

@media screen and (max-width: 768px) {
  .product-wrapper {
    padding: 2rem 1.5rem;
    font-size: 1.1rem;
  }

  .product-heading {
    font-size: 1.5rem;
    padding: 2rem 2rem;
  }
}


/* 727071 */