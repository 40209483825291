.technology-heading {
  font-size: 3rem;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2rem !important;
}

.technology-description {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  padding: 2rem !important;
}

.technology-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.process-heading {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.process-description {
  font-size: 1.25rem;
}

@media screen and (max-width: 768px) {
  .technology-heading {
    font-size: 1.5rem;
  }

  .technology-description {
    font-size: 1.1rem;
    line-height: 28px;
    padding-top: 0 !important;
  }
}