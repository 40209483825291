.homepage-heading .title-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  top: -5rem;
}

.homepage-heading .title-container .title-wrapper {
 margin: auto;
}

.homepage-heading {
  background-color: #222 !important;
  height: 100% !important;
}

.homepage-heading .heading-container {
  width: 55%;
  margin: auto;
}

.homepage-heading .sub-heading-container {
  width: 55%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .homepage-heading .heading-container {
    width: 100%;
    margin: auto;
    padding: 1rem;
    padding-top: 3rem;
  }
  
  .homepage-heading .sub-heading-container {
    width: 100%;
    margin: auto;
    padding: 1rem;
    padding-bottom: 3rem;
  }

  .homepage-heading .title-container {
    top: 0;
  }
}

.homepage-heading .subtitle-container {
  margin-right: 5%;
  display: flex;
  justify-content: flex-end;
}

.homepage-heading .subtitle-container .subtitle-wrapper {
  width: 55%;
  text-align: right;
  margin-top: 10em;
}

.anton-regular {
  font-family: "Anton", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.antonio-regular {
  font-family: "Antonio", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.impact-quote {
  line-height: 1.2em;
  font-size: 1.5rem;
  font-style: italic;
  justify-content: center;
}

.impact-quote-author {
  margin-top: 1rem;
  text-align: right;
  margin-right: 2rem;
  font-size: 1.5rem;
  font-style: italic;
}

.impact-description {
  font-size: 1.5rem;
  line-height: 1.25em;
  text-align: justify;
}

.impact-button {
  margin-top: 1rem;
  float: right;
  font-family: "Poppins", sans-serif !important;
}

.poppins-font {
  font-family: "Poppins", sans-serif !important;
}

@media screen and (max-width: 768px) {
  /* .impact-quote {
    font-size: 1.5rem;
  }

  .impact-quote-author {
    font-size: 1.25rem;
  }

  .impact-description {
    font-size: 1.25rem;
  } */

  .homepage-heading .subtitle-container .subtitle-wrapper {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    margin: 2rem 1.25rem !important;
  }

  .homepage-heading .subtitle-container {
    margin-right: 0;
  }

  .homepage-heading .title-container {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
}

.impact-container {
  padding: 8em 0em;
  margin-top: 5em;
}

@media screen and (max-width: 768px) {
  .impact-container {
    padding: 4em 1em;
    margin-top: 2em;
  }

  .impact-quote, .impact-quote-author, .impact-description, .impact-button {
    font-size: 1.25rem;
  }
}

.technology-container {
  padding: 3em;
  background-color: #1b1c1d;
  border-radius: 10px;
  color: white;
}

.technology-container .tech-description {
  font-size: 1.5rem;
  text-align: justify;
  padding: 2rem;
  line-height: 1.25em;
}

.technology-container .tech-heading {
  font-size: 2em !important;
  color: white !important;
}

@media screen and (max-width: 768px) {
  .technology-container {
    padding: 1em;
  }

  .technology-container .tech-description {
    font-size: 1.25rem;
  }

  .technology-container .tech-heading {
    font-size: 1.5em !important;
  }
}

.green-button {
  background-color: #4c874b !important;
}