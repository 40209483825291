.poppins-font {
  font-family: "Poppins", sans-serif !important;
}

.info-container {
  padding: 2rem 2rem;
  font-size: 1.5rem;
  text-align: center;
  line-height: 2rem;
}

.info-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: left;
}

.small-title {
  font-size: 2rem;
}

.featured-desc {
  font-size: 1.3rem;
  margin-top: 2rem;
  text-align: center;
}

.info-description {
  text-align: left;
}

.feature-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .info-container {
    font-size: 1rem;
    padding: 2rem 1rem;
  }

  .info-title {
    text-align: center;
  }

  .info-description {
    text-align: center;
  }
  
  .info-container .team-card {
    text-align: center !important;
  }

  .team-card img {
    margin: auto !important;
  }
}