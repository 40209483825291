.home-desktop-menu {
  /* text-align: center; */
  height: 100vh;
  padding: 0 !important;
  /* background-image: url('./../../../assets/HEADING_BACKGROUND.jpg') !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important; */
}

.home-desktop-menu .menu {
  position: relative;
  z-index: 999;
}

.home-desktop-menu .logo-container {
  padding: 0 1.5rem;
}

.home-desktop-menu .logo-container .logo {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: 0 !important;
}

.home-desktop-menu .logo-container .logo::before {
  width: 0;
}

.home-desktop-menu .menu-container {
  justify-content: flex-end;
  padding: 0 1.5rem;
}

.home-desktop-menu .menu-container .menu::before {
  width: 0;
}

.home-desktop-menu .menu-container .menu {
  border-left: 0 !important;
  font-weight: bold;
}